import { designSystem } from "@planningcenter/tapestry-react"

// Themes for buttons in tapestry-react
// https://planningcenter.github.io/tapestry-react/theming#themes

const themes = {
  neutral: {
    fill: {
      backgroundColor: "var(--t-fill-color-button-neutral-solid-default)",
      color: "var(--t-text-color-default-inverted)",
      fontWeight: "var(--t-font-weight-regular)",
      hover: {
        backgroundColor: "var(--t-fill-color-button-neutral-solid-hover)",
      },
      active: {
        backgroundColor: "var(--t-fill-color-button-neutral-solid-active)",
      },
    },
  },
  primary: {
    fill: {
      backgroundColor: "var(--t-fill-color-button-interaction-solid-default)",
      color: "var(--t-text-color-default-inverted)",
      hover: {
        backgroundColor: "var(--t-fill-color-button-interaction-solid-hover)",
      },
      active: {
        backgroundColor: "var(--t-fill-color-button-interaction-solid-active)",
      },
      disabled: {
        backgroundColor: "var(--t-fill-color-button-neutral-solid-disabled)",
        color: "var(--t-text-color-default-disabled)",
      },
    },
    outline: {
      color: "var(--t-text-color-status-info)",
      stroke: "var(--t-border-color-button-info)",
      backgroundColor: "transparent",
      hover: {
        backgroundColor: "var(--t-fill-color-button-interaction-ghost-hover)",
        color: "var(--t-text-color-status-info)",
      },
      active: {
        color: "var(--t-fill-color-button-info-ghost-active)",
        backgroundColor: "var(--t-text-color-default-inverted)",
      },
    },
    naked: {
      color: "var(--t-text-color-status-info)",
      hover: {
        backgroundColor: "var(--t-fill-color-button-interaction-ghost-hover)",
      },
    },
  },
  drawerPrimary: {
    fill: {
      backgroundColor: "neutral080",
      color: "primary",
      hover: { backgroundColor: "neutral070" },
    },
    outline: {
      backgroundColor: "transparent",
    },
    naked: {
      color: "primary",
    },
  },
  sidebarFilter: {
    naked: {
      justifyContent: "start",
      fontWeight: 600,
      color: "base0",
      paddingTop: "7px",
      paddingBottom: "7px",
      hover: {
        backgroundColor: "base0",
        color: "var(--t-text-color-default-inverted)",
      },
    },
    nakedNoHover: {
      justifyContent: "start",
      fontWeight: 600,
      color: "base0",
      paddingTop: "7px",
      paddingBottom: "7px",
    },
    fill: {
      backgroundColor: "var(--t-fill-color-status-info-ghost)",
      paddingTop: "7px",
      paddingBottom: "7px",
      color: "primary",
      fontWeight: 600,
    },
  },
  pageHeader: {
    fill: {
      backgroundColor: "var(--t-fill-color-button-interaction-solid-default)",
      borderColor: "transparent",
      color: "var(--t-text-color-default-inverted)",
      hover: {
        backgroundColor: "var(--t-fill-color-button-interaction-solid-hover)",
        borderColor: "productPeople54",
      },
      active: {
        backgroundColor: "var(--t-fill-color-button-interaction-solid-active)",
      },
    },
    primary: {
      backgroundColor: "var(--t-text-color-default-inverted)",
      borderColor: "var(--t-text-color-default-inverted)",
      color: "var(--t-fill-color-button-interaction-solid-active) !important",
      hover: {
        backgroundColor: "var(--t-fill-color-button-interaction-ghost-hover)",
      },
      active: {
        backgroundColor: "var(--t-fill-color-button-interaction-ghost-active)",
      },
    },
  },
}

// Temporarily update theme variants until Tapestry-React is up-to-spec

const { defaultTheme } = designSystem
const buttonThemes = Object.keys(defaultTheme.button.themes)
const buttonVariants = ["fill", "outline", "naked"]

buttonThemes.forEach(theme => {
  buttonVariants.forEach(variant => {
    themes[theme] = themes[theme] || {}
    themes[theme][variant] = themes[theme][variant] || {}
    themes[theme][variant].fontWeight = 400
  })
})

export default { themes }
